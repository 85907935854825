import config from "@/config.js";
import cookie from "@/scripts/cookies.js";
export default {
    isLoggedIn() {
        if (cookie.check("token")) {
            return true;
        }
        return false;
    },
    async isAdmin() {
        if (this.isLoggedIn()) {
            let isAdmin = false;
            const token = cookie.value("token");
            await fetch(
                `${config.server.host}:${config.server.PORT}/api/v0/users/isAdmin`,
                {
                    headers: {
                        Authorization: token,
                    },
                }
            )
                .then((result) => {
                    if (result.status === 401) {
                        cookie.remove("token");
                        window.location.href = "/";
                    }
                    return result.json();
                })
                .then((result) => {
                    if (result.admin) isAdmin = true;
                })
                .catch((err) => {
                    if (err) {
                        console.log(err);
                    }
                });
            return isAdmin;
        }
    },
};
