// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrapper-34n8zm{display:flex;justify-content:space-between;width:200px;color:#fff}.wrapper-34n8zm .media-2o5jNU{line-height:35px;width:35px;height:35px;background-color:#fff;border-radius:50%;font-size:20px;color:#555;cursor:pointer}.wrapper-34n8zm .media-2o5jNU .facebook-10w_7P{color:#3b5998}.wrapper-34n8zm .media-2o5jNU .twitter-2RGvfq{color:#00acee}.wrapper-34n8zm .media-2o5jNU .linkedin-1IrxXa{color:#0e76a8}", "",{"version":3,"sources":["webpack://./src/styles/home/Media.module.scss"],"names":[],"mappings":"AAAA,gBACI,YAAA,CACA,6BAAA,CACA,WAAA,CACA,UAAA,CAEA,8BACI,gBAAA,CACA,UAAA,CACA,WAAA,CACA,qBAAA,CACA,iBAAA,CACA,cAAA,CACA,UAAA,CACA,cAAA,CACA,+CACI,aAAA,CAEJ,8CACI,aAAA,CAEJ,+CACI,aAAA","sourcesContent":[".wrapper {\r\n    display: flex;\r\n    justify-content: space-between;\r\n    width: 200px;\r\n    color: white;\r\n    // background-color: #888;\r\n    .media {\r\n        line-height: 35px;\r\n        width: 35px;\r\n        height: 35px;\r\n        background-color: #fff;\r\n        border-radius: 50%;\r\n        font-size: 20px;\r\n        color: #555;\r\n        cursor: pointer;\r\n        .facebook {\r\n            color: #3b5998;\r\n        }\r\n        .twitter {\r\n            color: #00acee;\r\n        }\r\n        .linkedin {\r\n            color: #0e76a8;\r\n        }\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "wrapper-34n8zm",
	"media": "media-2o5jNU",
	"facebook": "facebook-10w_7P",
	"twitter": "twitter-2RGvfq",
	"linkedin": "linkedin-1IrxXa"
};
export default ___CSS_LOADER_EXPORT___;
