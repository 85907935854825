import config from "@/config.js";
import api from "./api";
export default {
    cookies() {
        const _temp = document.cookie.split("; ");
        const _temp2 = _temp.map((pair) => pair.split("="));
        return _temp2.map((pair) => {
            let cookies = {};
            cookies[pair[0]] = pair[1];
            return cookies;
        });
    },
    add(name = "", value = "", expireDays = 0) {
        let maxAge = "";
        if (expireDays) {
            maxAge = expireDays * 24 * 60 * 60;
        }
        name
            ? (document.cookie = `${name}=${value};max-age=${maxAge}`)
            : console.error(`Cookie.add error: no name or value.`);
    },
    value(name = "") {
        try {
            if (!name) return console.error(`No name in properties`);
            if (!this.cookies()[0][name])
                return console.log(`${name} not found`);
            const value = this.cookies().find((pair) =>
                pair.hasOwnProperty(name)
            )[name];
            return value;
        } catch (error) {
            console.error("Error in cookie.value", error);
        }
    },
    check(name = "") {
        if (this.cookies()[0][name]) return true;
        return false;
    },
    async remove(name = "") {
        // TODO Dodać zapytanie do serwera
        if (name) {
            await api.logout();
            document.cookie = `${name}="";max-age=-1`;
        } else {
            console.error(`Cookie.add error: no name or value.`);
        }
    },
    getSignature() {
        if (this.value("token")) {
            return this.value("token").split(".")[1];
        }
    },
};
