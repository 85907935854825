import style from "@/styles/home/Date.module.scss";

const Date = (props) => {
    return (
        <div className={style.date}>
            <div className={style.day}>{props.day}</div>
            <div className={style.restDate}>{props.date}</div>
        </div>
    );
};

export default Date;
