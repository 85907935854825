import { Link } from "react-router-dom";
import HTMLHeader from "@/scripts/HTMLHead";
import config from "@/config.js";
import auth from "@/scripts/auth.js";
import cookie from "@/scripts/cookies.js";

import style from "@/styles/userAuth/Recover.module.scss";
import { useEffect, useState } from "react";
import api from "../../scripts/api";

const Recover = () => {
    const [error, setError] = useState("");
    const [login, setLogin] = useState("");
    const [password, setPassword] = useState("");
    const [pin, setPin] = useState("");
    const [isEmailSent, setIsEmailSent] = useState(false);
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (isEmailSent) {
            console.log("Odzyskiwanie");
            console.log(pin);
            console.log(password);
            const response = await api.changeRecoverPassword(pin, password);
            if (response.status === 200) {
                setError("");
                window.location.href = "/login";
            } else {
                setError("Podany PIN jest błędny");
            }
        } else {
            console.log("Wysyłanie maila");
            const response = await api.sendRecoverMail(login);
            setLogin("");
            if (response.status === 200) {
                setIsEmailSent(true);
                setError("");
            } else {
                setError("Nie ma takiego użytkownika");
            }
        }
    };
    return (
        <>
            <HTMLHeader title="Odzyskaj hasło - Blog | Delta Strom" />
            <div className={style.wrapper}>
                <Link to="/">
                    <h1>Delta Storm</h1>
                </Link>
                <h2>Odzyskaj hasło</h2>
                <div className={`${style.error} ${error ? style.display : ""}`}>
                    {error}
                </div>
                <form action="/" onSubmit={handleSubmit} className={style.form}>
                    {isEmailSent ? (
                        <>
                            <p>Wiadomość została wysłana na mail</p>
                            <p>Wprowadź PIN</p>
                            <input
                                type="text"
                                name="pin"
                                className={style.pin}
                                onChange={(e) => setPin(e.target.value)}
                                value={pin}
                                placeholder="PIN..."
                                required
                            />
                            <input
                                type="password"
                                name="password"
                                className={style.password}
                                onChange={(e) => setPassword(e.target.value)}
                                value={password}
                                placeholder="Nowe hasło..."
                                required
                            />
                        </>
                    ) : (
                        <input
                            type="text"
                            name="login"
                            className={style.login}
                            onChange={(e) => setLogin(e.target.value)}
                            value={login}
                            placeholder="Login..."
                            required
                        />
                    )}

                    <div className={style.linkWrapper}>
                        <Link to="/login" className={style.recover}>
                            Zaloguj
                        </Link>
                        <Link to="/register" className={style.register}>
                            Zarejestruj
                        </Link>
                    </div>
                    <input
                        type="submit"
                        value="Zaloguj"
                        className={style.button}
                    />
                </form>
            </div>
        </>
    );
};

export default Recover;
