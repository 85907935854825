// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".date-KC01w4{background-color:#444;color:#ddd;text-align:center;padding:5px 10px}.date-KC01w4 .day-2skitu{font-size:24px;margin-bottom:5px;font-weight:700}", "",{"version":3,"sources":["webpack://./src/styles/home/Date.module.scss"],"names":[],"mappings":"AAAA,aACI,qBAAA,CACA,UAAA,CACA,iBAAA,CACA,gBAAA,CACA,yBACI,cAAA,CACA,iBAAA,CACA,eAAA","sourcesContent":[".date {\r\n    background-color: #444;\r\n    color: #ddd;\r\n    text-align: center;\r\n    padding: 5px 10px;\r\n    .day {\r\n        font-size: 24px;\r\n        margin-bottom: 5px;\r\n        font-weight: 700;\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"date": "date-KC01w4",
	"day": "day-2skitu"
};
export default ___CSS_LOADER_EXPORT___;
