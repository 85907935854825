import style from "@/styles/home/Header.module.scss";
import Media from "@/components/home/Media";
import { Link } from "react-router-dom";
import auth from "@/scripts/auth.js";
import cookie from "@/scripts/cookies.js";

//- Components
const Header = () => {
    return (
        <>
            <header>
                <div className={style.wrapper}>
                    <h1
                        className={style.logo}
                        onClick={() => {
                            window.location.href = "/";
                        }}
                    >
                        Delta Storm
                    </h1>
                    <div className={style.media}>
                        <Media />
                    </div>
                    <div className={style.signWrapper}>
                        {/*
                        //! Musi być adminem
                        // [tmp]
                        */}
                        {auth.isLoggedIn() ? (
                            <>
                                <Link to="/account">Account</Link>
                                <Link
                                    to="/"
                                    onClick={() => {
                                        cookie.remove("token");
                                        window.location.href = "/";
                                    }}
                                >
                                    Logout
                                </Link>
                            </>
                        ) : (
                            <>
                                <Link to="/login">Login</Link>
                                <Link to="/register">Register</Link>
                            </>
                        )}
                    </div>
                </div>
            </header>
            <div className={style.nav}>
                <Nav />
            </div>
        </>
    );
};

const Nav = () => {
    return (
        <>
            <nav>
                <form action="" className={style.searchForm}>
                    <button type="submit" className={style.search}>
                        <i className="fas fa-search"></i>
                    </button>
                    <input type="text" name="title" id="title" />
                </form>
                <ul>
                    <li>Categories</li>
                    <li>About Us</li>
                    <li>Manifesto</li>
                    <li>Subscribe</li>
                    <li>Contact</li>
                </ul>
            </nav>
        </>
    );
};

export default Header;
