import Media from "@/components/home/Media.jsx";
import style from "@/styles/home/Footer.module.scss";

const Footer = () => {
    return (
        <footer>
            <h2>&copy; Copyright 2021 | Delta Storm</h2>
            <p>All Rights Reserved | Created by Mateusz Szczykutowicz</p>
            <div className={style.media}>
                <Media />
            </div>
        </footer>
    );
};

export default Footer;
