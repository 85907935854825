import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";

//- Style
import style from "@/styles/home/Date.module.scss";

//- Components
import Header from "@/components/home/Header";
import Main from "@/components/home/Main";
import Footer from "@/components/home/Footer";
import Login from "@/components/userAuth/Login";
import Register from "@/components/userAuth/Register";
import Recover from "@/components/userAuth/Recover";
import cookie from "@/scripts/cookies.js";
import ArticlePage from "@/components/articlePage/ArticlePage.jsx";

//- Scripts
import HTMLHeader from "@/scripts/HTMLHead";
import { useEffect } from "react";
import api from "../scripts/api";
import Account from "./accountPage/Account";

//- Logic

//- App component
const App = () => {
    useEffect(async () => {
        api.checkConnection();
    });
    return (
        <>
            {/* <HTMLHeader title="Blog - Delta Storm" /> */}
            <Router>
                <Switch>
                    <Route exact path="/" component={Home} />
                    <Route exact path="/tags/:tag" component={Home} />
                    <Route exact path="/login" component={Login} />
                    <Route exact path="/register" component={Register} />
                    <Route exact path="/recover" component={Recover} />
                    <Route
                        exact
                        path="/article/:slug"
                        component={ArticlePage}
                    />
                    <Route exact path="/account" component={Account} />
                </Switch>
            </Router>
        </>
    );
};

const Home = () => {
    return (
        <>
            <Header />
            <Main />
            <Footer />
        </>
    );
};

export default App;
