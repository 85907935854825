import Asaid from "@/components/home/Asaid.jsx";
import DateComponent from "@/components/home/Date.jsx";
import Article from "@/components/home/Article.jsx";
import style from "@/styles/home/Main.module.scss";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import config from "@/config.js";
import auth from "@/scripts/auth.js";
import api from "@/scripts/api.js";
import HTMLHeader from "@/scripts/HTMLHead";
import cookie from "@/scripts/cookies.js";

const Main = () => {
    //? States
    const [articles, setArticles] = useState([]);
    const [currentPage, setPage] = useState(1);
    const [maxPage, setMaxPage] = useState(1);
    const [currentArticles, setCurrentArticles] = useState([]);
    const [highlightedArticle, sethighlightedArticle] = useState();
    const { tag } = useParams();
    const query = useQuery();

    //? Functions
    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    const prevPage = () => {
        if (currentPage > 1) {
            setPage(currentPage - 1);
            window.scroll(0, 0);
        }
    };
    const nextPage = () => {
        if (currentPage < maxPage) {
            setPage(currentPage + 1);
            window.scroll(0, 0);
        }
    };

    //? Effects
    useEffect(async () => {
        // [tmp] tag: "JavaScript"
        // TODO props ogarnąć
        const params = {};
        if (tag) {
            params.tag = tag;
        }
        if (query.get("title")) {
            params.title = query.get("title");
        }
        const data = await api.getAllArticles(params);
        const result = data.articlesData;
        let articlesTab = data.articlesTab;
        articlesTab = articlesTab.reverse();
        articlesTab.splice(0, 1);
        sethighlightedArticle(result.data[result.data.length - 1]);
        setArticles(articlesTab);
        setMaxPage(Math.ceil(articlesTab.length / 5) || 1);
    }, []);

    useEffect(() => {
        const min = (currentPage - 1) * 5;
        const max = (currentPage - 1) * 5 + 5;

        const articlesTab = articles.filter((val, i) => {
            if (i >= min && i < max) {
                return true;
            }
        });
        setCurrentArticles(articlesTab);
    }, [currentPage, articles]);

    //? Structure
    return (
        <main>
            <HTMLHeader title="Home - Blog | Delta Strom" />
            <div className={style.wrapper}>
                <div className={style.MainArticleWrapper}>
                    <MainArticle data={highlightedArticle} />
                </div>
                <div className={style.content}>
                    <div className={style.ArticleWrapper}>
                        <Articles articles={currentArticles} />
                    </div>
                    <div className={style.AsaidWrapper}>
                        <Asaid />
                    </div>
                </div>
                <div className={style.pageButtons}>
                    <div
                        onClick={prevPage}
                        className={`${style.pageButton} ${
                            currentPage > 1 ? "" : style.disable
                        }`}
                    >
                        &lt;
                    </div>
                    <div className={style.page}>
                        {currentPage} / {maxPage}
                    </div>
                    <div
                        onClick={nextPage}
                        className={`${style.pageButton} ${
                            currentPage < maxPage ? "" : style.disable
                        }`}
                    >
                        &gt;
                    </div>
                </div>
            </div>
        </main>
    );
};

const MainArticle = (props) => {
    const getDay = (time) => {
        return time.getDate();
    };

    const getDate = (time) => {
        const timeString = `${
            time.getMonth() < 9
                ? `0${time.getMonth() + 1}`
                : time.getMonth() + 1
        }.${time.getFullYear()}`;
        return timeString;
    };
    const { title, content, author } = props.data || {
        title: "Title",
        content:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quod praesentium est minima obcaecati quas reiciendis magni assumenda distinctio dolore corporis tempore odit nostrum aperiam alias nisi quae eaque vel iusto tenetur soluta doloremque, beatae quos ipsa tempora. Dolor accusantium, laboriosam voluptate quisquam repellat saepe delectus, quidem a enim nam quis beatae et, repudiandae praesentium tempore consequuntur nihil similique culpa minus modi incidunt ab. Commodi veritatis soluta distinctio voluptates? Quam quia, eos corporis reiciendis voluptas mollitia beatae dignissimos velit neque commodi harum quo aperiam officia saepe dolor fugiat itaque minus tempora ducimus maiores iste? Voluptate deleniti numquam, praesentium placeat eos illum?",
        author: "author",
    };
    let time, day, restOfDate;
    if (props.data) {
        time = new Date(props.data.createdAt);
        day = getDay(time);
        restOfDate = getDate(time);
    }
    const backgroundImage = {
        backgroundImage: `url(
            "https://images.unsplash.com/photo-1517694712202-14dd9538aa97?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80"
        )`,
    };

    return (
        <>
            <div className={style.imageContainer} style={backgroundImage}></div>
            <div className={style.headerWrapper}>
                <div className={style.articleContent}>
                    <h2>{title}</h2>
                    <h3>{author}</h3>
                    <div className={style.date}>
                        {/* // [tmp] */}
                        <DateComponent
                            day={day || "01"}
                            date={restOfDate || "01.1977"}
                        />
                    </div>

                    <p>{content}</p>
                    <button>Read more</button>
                </div>
                <div className={style.imageWrapper}>
                    <img
                        src="https://images.unsplash.com/photo-1517694712202-14dd9538aa97?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80"
                        alt="placeholder"
                    />
                </div>
            </div>
        </>
    );
};

const Articles = (props) => {
    const articles = props.articles;
    //[tmp]
    const [admin, setAdmin] = useState("");
    useEffect(async () => {
        setAdmin(await auth.isAdmin());
    }, []);
    return (
        <>
            {/* <Article
                key={1}
                views={312}
                comments={12}
                time={new Date()}
                author={"Psikut"}
                isLoggedIn={auth.isLoggedIn()}
                isAdmin={admin}
                content={
                    "To jest przykładowy kontent, który będzie wyświetlany na stronie. Lorem ipsum dolor sit amet consectetur adipisicing elit. Officia possimus cupiditate fugiat autem, asperiores eum facilis repellat impedit quod molestias. Molestias quibusdam consequuntur soluta aut eius quisquam natus vel asperiores fugit omnis. Optio nobis voluptates dignissimos ducimus!"
                }
                title={"Lorem, ipsum dolor"}
                img={
                    "https://images.unsplash.com/photo-1500058430940-0292a0c1f59e?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80"
                }
            />
            <Article
                key={2}
                views={641}
                comments={51}
                time={new Date(2021, 0, 19)}
                author={"Psikut"}
                isLoggedIn={auth.isLoggedIn()}
                isAdmin={admin}
                content={
                    "Officia possimus cupiditate fugiat autem, asperiores eum facilis repellat impedit quod molestias. To jest przykładowy kontent, który będzie wyświetlany na stronie. Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestias quibusdam consequuntur soluta aut eius quisquam natus vel asperiores fugit omnis. Optio nobis voluptates dignissimos ducimus!"
                }
                title={"Illum, quam. 2"}
                img={
                    "https://images.unsplash.com/photo-1518378379207-3418372afea3?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80"
                }
            />
            <Article
                key={3}
                views={1274}
                comments={137}
                time={new Date(2021, 0, 17)}
                author={"Psikut"}
                isLoggedIn={auth.isLoggedIn()}
                isAdmin={admin}
                content={
                    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores impedit incidunt harum iusto quibusdam esse! Amet delectus asperiores illo, sint fugit ea saepe illum nisi dignissimos impedit! Molestias quibusdam consequuntur soluta aut eius quisquam natus vel asperiores fugit omnis. Optio nobis voluptates dignissimos ducimus!"
                }
                title={"Commodi voluptatem magnam"}
                img={
                    "https://images.unsplash.com/photo-1596003906949-67221c37965c?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80"
                }
            /> */}
            {articles}
        </>
    );
};

export default Main;
