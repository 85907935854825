import moduleName from "@/styles/home/Asaid.module.scss";

const Asaid = () => {
    return (
        <aside>
            <h2>Aside</h2>
        </aside>
    );
};

export default Asaid;
