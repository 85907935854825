import DateComponent from "@/components/home/Date.jsx";
import style from "@/styles/home/Article.module.scss";
import { Link } from "react-router-dom";

const getDay = (time) => {
    return time.getDate();
};

const getDate = (time) => {
    const timeString = `${
        time.getMonth() < 9 ? `0${time.getMonth() + 1}` : time.getMonth() + 1
    }.${time.getFullYear()}`;
    return timeString;
};

const getAdminButton = () => (
    <>
        <button className={`${style.button} ${style.editButton}`}>
            <i className="fas fa-edit"></i>
        </button>
        <button className={`${style.button} ${style.deleteButton}`}>
            <i className="fas fa-trash"></i>
        </button>
    </>
);

const getUserButton = (props) => (
    <>
        <button className={`${style.button} ${style.viewButton}`}>
            <i className="fas fa-eye"></i>
            <span>{props.views}</span>
        </button>
        <button className={`${style.button} ${style.commentsButton}`}>
            <i className="fas fa-comments"></i>
            <span>{props.comments}</span>
        </button>
    </>
);

function Article(props) {
    const day = getDay(props.time);
    const date = getDate(props.time);
    return (
        <>
            <div className={style.wrapper}>
                <div className={style.leftContainer}>
                    <DateComponent day={day} date={date} />
                    {props.isAdmin
                        ? getAdminButton(props)
                        : getUserButton(props)}
                </div>
                <div className={style.img}>
                    <img src={props.img} alt="placeholder" />
                </div>
                <div className={style.content}>
                    <h3>{props.title}</h3>
                    <h4>{props.author}</h4>
                    <p>{props.content}</p>
                    <Link to={`/article/${props.slug}`}>Read more...</Link>
                </div>
            </div>
        </>
    );
}

export default Article;
