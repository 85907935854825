// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "body footer{text-align:center;background-color:#333;color:#bbb;padding:75px 0px}body footer h2{font-size:20px;letter-spacing:2px;margin-bottom:20px}body footer .media-3mtkMT{margin-top:50px;display:flex;justify-content:center}", "",{"version":3,"sources":["webpack://./src/styles/home/Footer.module.scss"],"names":[],"mappings":"AACI,YACI,iBAAA,CACA,qBAAA,CACA,UAAA,CACA,gBAAA,CACA,eACI,cAAA,CACA,kBAAA,CACA,kBAAA,CAEJ,0BACI,eAAA,CACA,YAAA,CACA,sBAAA","sourcesContent":["body {\r\n    footer {\r\n        text-align: center;\r\n        background-color: #333;\r\n        color: #bbb;\r\n        padding: 75px 0px;\r\n        h2 {\r\n            font-size: 20px;\r\n            letter-spacing: 2px;\r\n            margin-bottom: 20px;\r\n        }\r\n        .media {\r\n            margin-top: 50px;\r\n            display: flex;\r\n            justify-content: center;\r\n        }\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"media": "media-3mtkMT"
};
export default ___CSS_LOADER_EXPORT___;
