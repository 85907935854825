import { Link } from "react-router-dom";
import HTMLHeader from "@/scripts/HTMLHead";
import config from "@/config.js";
import auth from "@/scripts/auth.js";
import cookie from "@/scripts/cookies.js";

import style from "@/styles/login/Login.module.scss";
import { useEffect, useState } from "react";
import api from "../../scripts/api";

const Login = () => {
    const [error, setError] = useState("");
    const [login, setLogin] = useState("");
    const [password, setPassword] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault();
        const response = await api.login(login, password);
        if (response) {
            setError(response);
        }
        setPassword("");
    };

    useEffect(() => {
        if (auth.isLoggedIn()) {
            window.location.href = "/";
        }
    }, []);
    return (
        <>
            <HTMLHeader title="Login - Blog | Delta Strom" />
            <div className={style.wrapper}>
                <Link to="/">
                    <h1>Delta Storm</h1>
                </Link>
                <h2>Logowanie</h2>
                <div className={`${style.error} ${error ? style.display : ""}`}>
                    {error}
                </div>
                <form action="/" onSubmit={handleSubmit} className={style.form}>
                    <input
                        type="text"
                        name="login"
                        className={style.login}
                        onChange={(e) => setLogin(e.target.value)}
                        value={login}
                        placeholder="Login..."
                        required
                    />
                    <input
                        type="password"
                        name="password"
                        className={style.password}
                        onChange={(e) => setPassword(e.target.value)}
                        value={password}
                        placeholder="Hasło..."
                        required
                    />
                    <div className={style.linkWrapper}>
                        <Link to="/recover" className={style.recover}>
                            Nie pamiętam hasła
                        </Link>
                        <Link to="/register" className={style.register}>
                            Zarejestruj
                        </Link>
                    </div>
                    <input
                        type="submit"
                        value="Zaloguj"
                        className={style.button}
                    />
                </form>
            </div>
        </>
    );
};

export default Login;
