import { Link } from "react-router-dom";
import HTMLHeader from "@/scripts/HTMLHead";
import config from "@/config.js";

import style from "@/styles/register/Register.module.scss";
import { useState } from "react";
import api from "../../scripts/api";

const Register = () => {
    const [login, setLogin] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [email, setEmail] = useState("");
    const [error, setError] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault();
        const passwordRules = new RegExp(
            ".*(?=.*[A-Z]+.*).*(?=.*[1-9]+.*).*(?=.*[!@#$]+.*).*.{8,}",
            ""
        );

        if (password !== confirmPassword) {
            setError("Hasła nie są takie same");
            return false;
        }
        console.log(passwordRules.test(password));
        //!
        // TODO poprawić zabezpieczenia haseł - wymagane DUŻA i (1-9)
        if (!passwordRules.test(password)) {
            setError(
                <>
                    Hasło musi zawierać przynajmniej:
                    <br />
                    - 8 znaków
                    <br />
                    - 1 dużą literę
                    <br />
                    - 1 cyfrę
                    <br />- jeden ze znaków: !,@,#,$
                </>
            );
            return false;
        }
        const response = await api.register(login, password, email);
        setError(response);
        setConfirmPassword("");
        setPassword("");
    };

    return (
        <>
            <>
                <HTMLHeader title="Register - Blog | Delta Strom" />
                <div className={style.wrapper}>
                    <Link to="/">
                        <h1>Delta Storm</h1>
                    </Link>
                    <h2>Rejestracja</h2>
                    <div
                        className={`${style.error} ${
                            error ? style.display : ""
                        }`}
                    >
                        {error}
                    </div>
                    <form
                        action="/"
                        onSubmit={handleSubmit}
                        className={style.form}
                    >
                        <input
                            type="text"
                            name="login"
                            className={style.login}
                            value={login}
                            onChange={(e) => setLogin(e.target.value)}
                            placeholder="Login..."
                            required
                        />
                        <input
                            type="password"
                            name="password"
                            className={style.password}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="Hasło..."
                            required
                        />
                        <input
                            type="password"
                            name="confirmPassword"
                            className={style.password}
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            placeholder="Powtórz hasło..."
                            required
                        />
                        <input
                            type="email"
                            name="email"
                            className={style.password}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Email..."
                            required
                        />
                        <div className={style.linkWrapper}>
                            <Link to="/recover" className={style.recover}>
                                Nie pamiętam hasła
                            </Link>
                            <Link to="/login" className={style.register}>
                                Zaloguj
                            </Link>
                        </div>
                        <input
                            type="submit"
                            value="Zarejestruj"
                            className={style.button}
                        />
                    </form>
                </div>
            </>
        </>
    );
};

export default Register;
