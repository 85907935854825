import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import api from "@/scripts/api.js";
import auth from "@/scripts/auth.js";

const Account = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [oldPassword, setOldPassword] = useState("");
    const [createdAt, setCreatedAt] = useState("");
    const [login, setLogin] = useState("");

    const [isUpdatingEmail, setIsUpdatingEmail] = useState(false);
    const [isUpdatingPassword, setIsUpdatingPassword] = useState(false);

    const handleSubmitChangeEmail = async (e) => {
        e.preventDefault();
        setIsUpdatingEmail(false);
        const response = await api.changeEmail(email);
        console.log(response);
    };
    const handleSubmitChangePassword = async (e) => {
        e.preventDefault();
        console.log(password);
        console.log(oldPassword);
        setIsUpdatingPassword(false);
        const response = await api.changePassword(oldPassword, password);
        console.log(response);
    };

    const emailStructure = () => {
        if (isUpdatingEmail) {
            return (
                <>
                    <form action="" onSubmit={handleSubmitChangeEmail}>
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            name="email"
                        />
                        <button type="submit">Zmień email</button>
                    </form>
                </>
            );
        } else {
            return (
                <>
                    <h2>Email: {email}</h2>
                    <button
                        onClick={() => {
                            setIsUpdatingEmail(true);
                        }}
                    >
                        Zmień email
                    </button>
                </>
            );
        }
    };
    const passwordStructure = () => {
        if (isUpdatingPassword) {
            return (
                <>
                    <form action="" onSubmit={handleSubmitChangePassword}>
                        <input
                            type="password"
                            value={oldPassword}
                            onChange={(e) => setOldPassword(e.target.value)}
                            name="password"
                            placeholder="Stare hasło"
                        />
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            name="password"
                            placeholder="Nowe hasło"
                        />
                        <button type="submit">Zmień hasło</button>
                    </form>
                </>
            );
        } else {
            return (
                <button
                    onClick={() => {
                        setIsUpdatingPassword(true);
                    }}
                >
                    Zmień hasło
                </button>
            );
        }
    };

    useEffect(async () => {
        if (!auth.isLoggedIn()) {
            window.location.href = "/";
        }
        const response = await api.getInfoAboutAccount();
        const user = response.user;
        setEmail(user.email);
        setLogin(user.login);
        setCreatedAt(user.createdAt);
    }, []);

    return (
        <>
            <Link to="/">Home</Link>
            <h1>Login: {login}</h1>
            {emailStructure()}
            {passwordStructure()}
            <h3>Utworzono: {createdAt}</h3>
        </>
    );
};

export default Account;
