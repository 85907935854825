import styles from "@/styles/articlePage/Comment.scss";
import { useEffect, useState } from "react";
import cookies from "../../scripts/cookies";
import auth from "../../scripts/auth";
import api from "../../scripts/api";

const getDate = (date) => {
    return `
            ${date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()}.${
        date.getMonth() < 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
    }.${date.getFullYear()}
        `;
};

const Comment = (props) => {
    const {
        author,
        content,
        modified,
        updatedAt,
        id,
        createdAt,
        authorID,
    } = props;

    const [admin, setAdmin] = useState(false);
    const [modification, setModification] = useState(false);
    const [modifiedContent, setModifiedContent] = useState(content);
    const [isModified, setIsModified] = useState(modified);

    const contentArea = () => {
        if (modification) {
            return (
                <form action="" onSubmit={handleSubmitModifiedComment}>
                    <textarea
                        type="text"
                        name="content"
                        className={styles.modifiedContent}
                        onChange={(e) => setModifiedContent(e.target.value)}
                        value={modifiedContent}
                    />
                    <button type="submit" className={styles.modifyButton}>
                        Modyfikuj
                    </button>
                </form>
            );
        } else {
            return <p>{modifiedContent}</p>;
        }
    };

    const handleSubmitModifiedComment = async (e) => {
        e.preventDefault();
        const response = await api.modifyComment(modifiedContent, id);
        if (response.status === 200) {
            setIsModified(true);
            setModification(false);
        }
    };

    const handleClickDeleteComment = () => {
        api.deleteComment(id);
        window.location.reload();
    };

    const getButtons = () => {
        if (authorID === cookies.getSignature()) {
            return (
                <>
                    <button
                        onClick={() => {
                            if (modification) {
                                setModification(false);
                                setModifiedContent(content);
                            } else {
                                setModification(true);
                            }
                        }}
                    >
                        <i className="fas fa-edit"></i>
                    </button>
                    <button onClick={handleClickDeleteComment}>
                        <i className="fas fa-trash"></i>
                    </button>
                </>
            );
        }
        if (admin) {
            return (
                <>
                    <button onClick={handleClickDeleteComment}>
                        <i className="fas fa-trash"></i>
                    </button>
                </>
            );
        }
        return <></>;
    };

    useEffect(async () => {
        setAdmin(await auth.isAdmin());
    }, []);

    return (
        <>
            <article className={styles.wrapper}>
                <h3>Autor: {author}</h3>
                <h4>{createdAt}</h4>
                <div className={styles.modified}>
                    {isModified ? (
                        <p title={updatedAt}>( Modyfikowany )</p>
                    ) : (
                        ""
                    )}
                </div>
                {contentArea()}
                <div className={styles.buttons}>{getButtons()}</div>
            </article>
        </>
    );
};

export default Comment;
