import auth from "@/scripts/auth.js";
import config from "@/config.js";
import Article from "@/components/home/Article.jsx";
import cookie from "@/scripts/cookies.js";

export default {
    async getAllArticles({ tag, title }) {
        let path;
        if (tag) {
            path = `tags=${tag}`;
        } else if (title) {
            path = `title=${title}`;
        }

        let articlesTab = [];
        let articlesData = "";
        const admin = await auth.isAdmin();
        await fetch(
            `${config.server.host}:${config.server.PORT}/api/v0/articles?${path}`
        )
            .then((result) => result.json())
            .then((result) => {
                articlesData = result;
                const data = result.data || [];
                for (const el of data) {
                    const newDate = new Date(el.createdAt);
                    articlesTab.push(
                        <Article
                            key={el.slug}
                            time={newDate}
                            content={el.content}
                            title={el.title}
                            isLoggedIn={auth.isLoggedIn()}
                            isAdmin={admin}
                            slug={el.slug}
                            img={
                                "https://images.unsplash.com/photo-1500058430940-0292a0c1f59e?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80"
                            }
                        />
                    );
                }
            })
            .catch((err) => {
                if (err) {
                    return console.log(err);
                }
            });
        return { articlesTab, articlesData };
    },
    async getOneArticle(slug) {
        const response = await fetch(
            `${config.server.host}:${config.server.PORT}/api/v0/articles/${slug}`
        );
        return response.json();
    },
    async login(login, password) {
        let error = null;
        const formData = new FormData();
        formData.append("login", login);
        formData.append("password", password);

        await fetch(
            `${config.server.host}:${config.server.PORT}/api/v0/users/login`,
            { method: "POST", body: formData }
        )
            .then((result) => {
                return result.json();
            })
            .then((result) => {
                if (result.status === 200) {
                    cookie.add("token", result.token, 1);
                    cookie.check("token") ? (window.location.href = "/") : "";
                } else {
                    error = "Błędny login lub hasło";
                }
            })
            .catch((err) => {
                if (err) {
                    console.log("Error in login :>>", err);
                    error = "Error! Powiadom administratora";
                    return false;
                }
            });
        return error;
    },
    async checkConnection() {
        await fetch(
            `${config.server.host}:${config.server.PORT}/connection/`
        ).catch((err) => {
            console.log(err);
            if (err == "TypeError: Failed to fetch") {
                cookie.remove("token");
            }
        });
    },
    async logout() {
        const token = cookie.value("token");
        await fetch(
            `${config.server.host}:${config.server.PORT}/api/v0/users/logout`,
            {
                method: "POST",
                headers: {
                    Authorization: token,
                },
            }
        ).catch((err) => {
            console.log(err);
        });
    },
    async register(login, password, email) {
        let response = "";
        const formData = new FormData();
        formData.append("login", login);
        formData.append("password", password);
        formData.append("email", email);
        await fetch(
            `${config.server.host}:${config.server.PORT}/api/v0/users/`,
            {
                method: "POST",
                body: formData,
            }
        )
            .then((result) => {
                if (result.status === 200) {
                    if (result.ok) {
                        console.log("OK!!!!!");
                    }
                }
                return result.json();
            })
            .then((result) => {
                if (result.message === "User already exists")
                    response = "Taki login już istnieje";
                if (result.status === 201) {
                    window.location.href = "/login";
                }
            })
            .catch((err) => {
                if (err) {
                    console.log("Error in login :>>", err);
                    setError("Error! Contact the Administrator");
                    return false;
                }
            });

        return response;
    },
    async getArticleComments(slug) {
        const response = await fetch(
            `${config.server.host}:${config.server.PORT}/api/v0/comments/${slug}`
        );
        return response.json();
    },
    async sendRecoverMail(login) {
        const formData = new FormData();
        formData.append("login", login);
        const response = await fetch(
            `${config.server.host}:${config.server.PORT}/api/v0/users/recover`,
            { method: "POST", body: formData }
        );
        return response.json();
    },
    async changeRecoverPassword(pin, password) {
        const formData = new FormData();
        formData.append("code", pin);
        formData.append("password", password);
        const response = await fetch(
            `${config.server.host}:${config.server.PORT}/api/v0/users/recover`,
            { method: "PATCH", body: formData }
        );
        return response.json();
    },
    async addNewComment(content, article) {
        const token = cookie.value("token");
        const formData = new FormData();
        formData.append("content", content);
        formData.append("article", article);
        const response = await fetch(
            `${config.server.host}:${config.server.PORT}/api/v0/comments`,
            {
                method: "POST",
                body: formData,
                headers: {
                    Authorization: token,
                },
            }
        );
        return response.json();
    },
    async modifyComment(content, id) {
        const token = cookie.value("token");
        const formData = new FormData();
        formData.append("content", content);
        formData.append("id", id);
        const response = await fetch(
            `${config.server.host}:${config.server.PORT}/api/v0/comments`,
            {
                method: "PATCH",
                body: formData,
                headers: {
                    Authorization: token,
                },
            }
        );
        return response.json();
    },
    async deleteComment(id) {
        const token = cookie.value("token");
        const formData = new FormData();
        formData.append("id", id);
        const response = await fetch(
            `${config.server.host}:${config.server.PORT}/api/v0/comments`,
            {
                method: "DELETE",
                body: formData,
                headers: {
                    Authorization: token,
                },
            }
        );
        return response.json();
    },
    async getInfoAboutAccount() {
        const token = cookie.value("token");
        const response = await fetch(
            `${config.server.host}:${config.server.PORT}/api/v0/users`,
            {
                method: "GET",
                headers: {
                    Authorization: token,
                },
            }
        );
        return response.json();
    },
    async changeEmail(email) {
        const token = cookie.value("token");
        const formData = new FormData();
        formData.append("email", email);
        const response = await fetch(
            `${config.server.host}:${config.server.PORT}/api/v0/users/email`,
            {
                method: "PATCH",
                body: formData,
                headers: {
                    Authorization: token,
                },
            }
        );
        return response.json();
    },
    async changePassword(oldPassword, password) {
        const token = cookie.value("token");
        const formData = new FormData();
        formData.append("password", oldPassword);
        formData.append("newPassword", password);
        const response = await fetch(
            `${config.server.host}:${config.server.PORT}/api/v0/users/password`,
            {
                method: "PATCH",
                body: formData,
                headers: {
                    Authorization: token,
                },
            }
        );
        return response.json();
    },
};
