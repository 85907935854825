import { Helmet } from "react-helmet";

const HtmlHead = ({ title = "Untitled", scripts = null }) => {
    return (
        <Helmet>
            <meta charset="UTF-8" />
            <meta
                name="viewport"
                content="width=device-width, initial-scale=1.0"
            />
            <title>{title}</title>
            <link rel="preconnect" href="https://fonts.gstatic.com" />
            <link
                href="https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap"
                rel="stylesheet"
            />
            <script
                src="https://kit.fontawesome.com/0233149bfc.js"
                crossorigin="anonymous"
            ></script>
        </Helmet>
    );
};

export default HtmlHead;
