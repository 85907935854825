import { Link, useParams } from "react-router-dom";
import api from "@/scripts/api.js";
import auth from "@/scripts/auth.js";
import { useEffect, useState } from "react";
import styles from "@/styles/articlePage/ArticlePage.scss";
import Comment from "@/components/articlePage/Comment.jsx";

const getDate = (dateToConvert) => {
    const date = new Date(dateToConvert);
    return `
            ${date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()}.${
        date.getMonth() < 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
    }.${date.getFullYear()} ${
        date.getHours() < 10 ? "0" + date.getHours() : date.getHours()
    }:${date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()}
        `;
};

const ArticlePage = () => {
    const [article, setArticle] = useState({});
    const [comments, setComments] = useState([]);
    const [comment, setComment] = useState("");
    const { slug } = useParams();
    const date = new Date(article.createdAt);
    const getTags = (tags) => {
        let tagTab = [];
        for (let key in tags) {
            tagTab.push(
                <Link key={key} to={`/tags/${tags[key]}`}>
                    <div key={key} className={styles.tag}>
                        {tags[key]}
                    </div>
                </Link>
            );
        }
        return tagTab;
    };

    const addComment = () => {
        const handleChange = (e) => {
            setComment(e.value);
        };
        const handleSubmit = async (e) => {
            e.preventDefault();
            const response = await api.addNewComment(comment, article.slug);
            console.log(response);
            if (response.status === 201) {
                window.location.reload();
            }
        };
        if (auth.isLoggedIn()) {
            return (
                <>
                    <form
                        className={styles.addCommentForm}
                        onSubmit={handleSubmit}
                    >
                        {/* <input type="text" name="content" id="content" /> */}
                        <textarea
                            name="content"
                            id="content"
                            onChange={(e) => {
                                handleChange(e.target);
                            }}
                        ></textarea>
                        <button type="submit">Dodaj komentarz</button>
                    </form>
                </>
            );
        } else {
            return <></>;
        }
    };

    useEffect(async () => {
        api.getOneArticle(slug)
            .then((result) => {
                setArticle(result.data || {});
            })
            .catch((err) => {
                console.log(err);
                return false;
            });
        const commentsResponse = await api.getArticleComments(slug);
        if (commentsResponse.status === 200) {
            let commentsTab = [];
            for (let comment of commentsResponse.data) {
                commentsTab.push(
                    <Comment
                        key={comment.ID}
                        id={comment.ID}
                        content={comment.content}
                        author={comment.author}
                        createdAt={getDate(comment.createdAt)}
                        updatedAt={getDate(comment.updatedAt)}
                        modified={comment.modified}
                        authorID={comment.authorID}
                    />
                );
            }
            setComments(commentsTab.reverse());
        }
    }, []);

    return (
        <>
            <div className={styles.wrapper}>
                <Link to={"/"}>Home</Link>
                <section className={styles.article}>
                    <h1>{article.title}</h1>
                    <h2>Autor: {article.author}</h2>
                    <h3>Utworzono: {getDate(date)}</h3>
                    <div className={styles.tags}>{getTags(article.tags)}</div>
                    <p>{article.content}</p>
                </section>
                <section className={styles.comments}>
                    <h2>Comments:</h2>
                    {addComment()}
                    <div className={styles.commentWrapper}>{comments}</div>
                </section>
            </div>
        </>
    );
};

export default ArticlePage;
