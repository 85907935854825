import style from "@/styles/home/Media.module.scss";

const Media = () => {
    return (
        <>
            <div className={style.wrapper}>
                <div className={style.media}>
                    <i className={`fab fa-facebook-f ${style.facebook}`}></i>
                </div>
                <div className={style.media}>
                    <i className={`fab fa-twitter ${style.twitter}`}></i>
                </div>
                <div className={style.media}>
                    <i className={`fab fa-linkedin ${style.linkedin}`}></i>
                </div>
                <div className={style.media}>
                    <i className={`fas fa-envelope ${style.envelope}`}></i>
                </div>
            </div>
        </>
    );
};

export default Media;
